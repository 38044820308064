import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import camelCase from "lodash.camelcase";
import loadable from "@loadable/component";
import Layout from "../../components/layout/Layout";
import Seo from "@v4/gatsby-theme-talend/src/components/Seo/Seo";

const NotificationBar = loadable(() => import("../../components/modules/notificationBar/NotificationBar"));
const Hero = loadable(() => import("../../components/modules/hero/Hero"));
const LeftRight = loadable(() => import("../../components/modules/leftRight/LeftRight"));
const FullWidth = loadable(() => import("../../components/modules/fullWidth/FullWidth"));
const FullWidthImage = loadable(() => import("../../components/modules/fullWidthImage/FullWidthImage"));
const MultiItem = loadable(() => import("../../components/modules/multiItem/MultiItem"));
const CaseStudy = loadable(() => import("../../components/modules/caseStudy/CaseStudy"));
const Duplex = loadable(() => import("../../components/modules/duplex/Duplex"));
const PricingWrapper = loadable(() => import("../../components/modules/pricingWrapper/PricingWrapper"));
const ContentListing = loadable(() => import("../../components/modules/contentListing/ContentListing"));
const MiscModule = loadable(() => import("../../components/modules/miscModule/MiscModule"));
const JumpDestination = loadable(() =>
    import("@v4/gatsby-theme-talend/src/components/JumpDestination/JumpDestination")
);

export const query = graphql`
    query($id: String!, $locale: String!) {
        contentfulPage(id: { eq: $id }) {
            id
            title
            slug
            node_locale
            languages
            updatedAt
            createdAt
            metaFields {
                ...meta
            }
            navType
            navTheme
            modules {
                __typename
                ... on Node {
                    ...NotificationBar
                    ...Hero
                    ...LeftRight
                    ...FullWidth
                    ...FullWidthImage
                    ...MultiItem
                    ...CaseStudy
                    ...Duplex
                    ...PricingWrapper
                    ...ContentListing
                    ...Miscellaneous
                    ...JumpDestination
                }
            }
            bgColor
            bgGradient
            bgHex
            bgImage {
                public_id
                width
                height
                format
            }
            bgPosition
            bgSize
            bgRepeat
        }
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
        minimalNav: contentfulMenu(name: { eq: "Minimal Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
    }
`;

const moduleMap = {
    ContentfulHero: Hero,
    ContentfulLeftRight: LeftRight,
    ContentfulFullWidth: FullWidth,
    ContentfulFullWidthImage: FullWidthImage,
    ContentfulCaseStudy: CaseStudy,
    ContentfulMultiItem: MultiItem,
    ContentfulDuplex: Duplex,
    ContentfulPricingWrapper: PricingWrapper,
    ContentfulNotificationBar: NotificationBar,
    ContentfulContentListing: ContentListing,
    ContentfulMiscellaneous: MiscModule,
    ContentfulJumpDestination: JumpDestination,
};

export default function page({ data }) {
    const page = data.contentfulPage;

    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };
    const metaValues = page.metaFields ?? {};

    // Map modules for placement in the page's primary content area
    const modules = page.modules.map((el, i) => {
        let Module = moduleMap[el.__typename];

        // Notification Bar exception: passes data to the Layout component
        if (el.__typename === "ContentfulNotificationBar") {
            page.notificationBar = <Module key={i} {...el} />;
            return null;
        } else {
            // Otherwise, return the Module's component for in-page placement
            return <Module moduleId={`module-${i + 1}`} key={i} {...el} />;
        }
    });

    // Build BG styles object to pass to Layout component
    const styleProps = {
        bgColor: page.bgColor,
        bgGradient: page.bgGradient,
        bgHex: page.bgHex,
        bgImage: page.bgImage,
        bgPosition: page.bgPosition,
        bgSize: page.bgSize,
        bgRepeat: page.bgRepeat,
    };

    // If a minimalNav is available, use it. Otherwise use the default nav.
    const hasMinimalNav = data.minimalNav && page.navType && page.navType === "Minimal Nav";
    const headerNav = hasMinimalNav ? data.minimalNav : data.contentfulMenu;

    // Convert (camelCase) the navTheme string value into a className. Otherwise defaults to "darkOpaque"
    const bodyHeaderClass =
        data.contentfulPage && data.contentfulPage.navTheme ? camelCase(data.contentfulPage.navTheme) : "darkOpaque";

    return (
        <Layout
            headerMenu={headerNav}
            footerMenu={footerData}
            hasMinimalNav={hasMinimalNav}
            notificationBar={page.notificationBar}
            pageStyleProps={styleProps}
        >
            <Seo
                title={metaValues.metaTitle || page.title}
                pathname={page.slug}
                description={metaValues.metaDescription}
                canonicalUrl={metaValues.canonicalUrl}
                canonicalUrlOverride={metaValues.canonicalUrlOverride}
                robots={metaValues.metaRobot}
                bingBot={metaValues.bingBot}
                googleBot={metaValues.googleBot}
                image={metaValues.metaImage}
                modifiedTime={page.updatedAt}
                createdTime={page.createdAt}
                languages={data.languages}
            />
            {data.contentfulPage && <Helmet bodyAttributes={{ class: bodyHeaderClass }} />}
            {modules}
        </Layout>
    );
}
